import React, { useState } from 'react';
import './Projects.css';
import { Fade } from 'react-reveal';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { featured_projects } from '../../data/featured_projects.json';
import Project from '../project/Project';
import Section from '../section/Section';
import FeaturedProject from '../featuredProject/FeaturedProject';

const useStyles = makeStyles((theme) => ({
  moreProjects: {
    '&': {
      margin: '20px auto',
      backgroundColor: '#39b175',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#0be779',
        boxShadow: 'none',
      },
    },
    '& > *': {
      color: 'white',
      padding: 4,
      fontSize: '15px',
      fontWeight: '600',
    },
  },
}));

const Projects = () => {
  const [showAll, setShowAll] = useState(false);
  const classes = useStyles();

  const initialProjects = featured_projects.slice(0, 4);
  const remainingProjects = featured_projects.slice(4);

  return (
    <Section title="Projects">
      <div className="projects-content">
        <ul className="projects-list">
          {initialProjects.map((project) => (
            <li key={`featured-project-${project.id}`}>
              <Fade bottom duration={1000} distance="20px">
                <FeaturedProject
                  name={project.name}
                  link={project.link}
                  description={project.description}
                  colour={project.colour}
                  languages={project.languages}
                />
              </Fade>
            </li>
          ))}
          {showAll && remainingProjects.map((project) => (
            <li key={`featured-project-${project.id}`}>
              <Fade bottom duration={1000} distance="20px">
                <FeaturedProject
                  name={project.name}
                  link={project.link}
                  description={project.description}
                  colour={project.colour}
                  languages={project.languages}
                />
              </Fade>
            </li>
          ))}
        </ul>
        {remainingProjects.length > 0 && (
          <Fade bottom duration={1000} distance="20px">
            <div className="more-projects-wrapper">
              <Button
                className={classes.moreProjects}
                type="button"
                variant="contained"
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? 'Show Less' : 'More Projects'}
              </Button>
            </div>
          </Fade>
        )}
      </div>
    </Section>
  );
};

export default Projects;
