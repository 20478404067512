// About.js

import React from 'react';
import './About.css';
import Typewriter from 'typewriter-effect';
import { Fade } from 'react-reveal';
import Section from '../section/Section';
import Skills from '../skills/Skills';

const About = () => {
  return (
    <Section title="About">
      <div className="about-content">
        <Fade duration={1000}>
          <div className="about-text">
            <h2>Who am I?</h2>
            <p>
              I'm Hajid Alkindi{' '}
              <span role="img" aria-label="lightning">
                😄
              </span>
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              I'm a senior Computer Science student at the German University of Technology (GUtech). I have a profound passion for Computer Science and am always seeking opportunities for growth and advancement.
            </p>
            <p>
              <span role="img" aria-label="lightning">
                ⚡
              </span>{' '}
              You can find me working with technologies such as Python, C++, JavaScript, React, React Native, and Node.js.
            </p>
            <div className="typewriter">
              <p className="typewriter-start">
                <span role="img" aria-label="lightning">
                  ⚡
                </span>{' '}
                I enjoy
              </p>
              <Typewriter
                options={{
                  strings: [
                    'learning new technologies',
                    'staying physically active',
                    'solving complex problems',
                    'collaborating with others',
                    'continuous self-improvement'
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
              <p>.</p>
            </div>
            <p>
              Since 2021, I've spent my time seeking and learning new technologies. This has led me to work on amazing projects, at some fantastic places, and with great people. I am a highly motivated and dedicated individual, always looking to contribute and grow in a dynamic work environment.
            </p>
            <div className="location-wrapper">
              <svg
                className="octicon octicon-location"
                viewBox="0 0 16 16"
                version="1.1"
                width="16"
                height="16"
                aria-hidden="true"
              >
                <path
                  fill="white"
                  fillRule="evenodd"
                  d="M11.536 3.464a5 5 0 010 7.072L8 14.07l-3.536-3.535a5 5 0 117.072-7.072v.001zm1.06 8.132a6.5 6.5 0 10-9.192 0l3.535 3.536a1.5 1.5 0 002.122 0l3.535-3.536zM8 9a2 2 0 100-4 2 2 0 000 4z"
                ></path>
              </svg>
              <p>Muscat, Oman</p>
            </div>
            <h2>Education</h2>
            <div className="education">
              <p><strong>BACHELOR IN COMPUTER SCIENCE</strong></p>
              <p>2021 - 2025</p>
              <p>German University of Technology in Oman</p>
              <br />
              <p><strong>GENERAL EDUCATION DIPLOMA</strong></p>
              <p>2017 - 2021</p>
              <p>Al Raneem Private School</p>
            </div>
            <div className="view-cv-button-wrapper">
              <a
                className="view-cv-button-link"
                href="https://drive.google.com/file/d/17b5JY0wjpW_M2EvTfo0n6GvLcxVTEqCg/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="view-cv-button">
                  View CV
                </div>
              </a>
            </div>
            <h2>Languages</h2>
            <p>Fluent in English and Arabic, with intermediate proficiency in Sawahli and German.</p>
          </div>
        </Fade>
        <Skills />
      </div>
    </Section>
  );
}

export default About;
